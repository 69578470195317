<template>
  <div>
    <div class="d-flex align-items-center">
      <b-form-group label="Order Date From" class="d-flex">
        <flat-pickr
          v-model="date_start"
          :config="{
            dateFormat: 'Y-m-d',
            maxDate: date_end,
          }"
          class="form-control font-small-3"
          placeholder="Choose Date"
          readonly="readonly"
        />
      </b-form-group>
      <h5 class="mx-1 mt-1 d-flex align-items-center">to</h5>
      <b-form-group label="Order Date To" class="d-flex">
        <flat-pickr
          v-model="date_end"
          :config="{
            dateFormat: 'Y-m-d',
            minDate: date_start,
          }"
          class="form-control font-small-3"
          placeholder="Choose Date"
          readonly="readonly"
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="btn-tour-skip mx-1 mt-1"
        @click="getOutletReports()"
      >
        <span class="mr-25 align-middle">Get Orders</span>
      </b-button>
      <b-button
        variant="outline-primary"
        class="btn-tour-skip mt-1"
        @click="ExportToExcel(items.id)"
      >
        <span class="mr-25 align-middle">Extract Orders</span>
      </b-button>
    </div>
    <b-card>
      <h1>{{ selected_status_type }}</h1>
      <div
        v-if="selected_status_type === `Profit & Loss Report`"
        class="d-flex"
      >
        <b-form-group label="Total Gross Profit" class="d-flex mt-1 mx-1">
          <p
            class="form-control font-small-3 d-flex align-items-center px-3 border-success"
          >
            {{ $helpers.formatTotal(total_gross_profit) }}
          </p>
        </b-form-group>
        <b-form-group label="Commission" class="d-flex mt-1">
          <p
            class="form-control font-small-3 d-flex align-items-center px-3 border-success"
          >
            {{ $helpers.formatTotal(total_commission) }}
          </p>
        </b-form-group>
      </div>
      <div v-if="selected_status_type === `Convert Risk Report`" class="d-flex">
        <b-form-group label="Total Convert Risk" class="d-flex mt-1 mx-1">
          <p
            class="form-control font-small-3 d-flex align-items-center px-3 border-success"
          >
            {{ $helpers.formatTotal(total_convert_risk) }}
          </p>
        </b-form-group>
      </div>
      <MyTable :columns="columns" :rows="items.orders" :is-loading="isLoading">
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'idcol'">
            <b-button
              v-if="props.row.shipment === 'Outlet'"
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
            <b-button
              v-else-if="props.row.shipment === 'Singapore'"
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
            <b-button
              v-else
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
          </div>

          <div v-else-if="props.column.field === 'order_date'">
            {{ $helpers.formatDate(props.row.created_at) }}
          </div>

          <div v-else-if="props.column.field === 'pickup'">
            {{
              props.row.pickup_date
                ? $helpers.formatDate(props.row.pickup_date)
                : 'N/A'
            }}
          </div>

          <div v-else-if="props.column.field === 'delivery'">
            {{
              props.row.delivery_date
                ? $helpers.formatDate(props.row.delivery_date)
                : 'N/A'
            }}
          </div>

          <div v-else-if="props.column.field === 'shipment'">
            <b-badge
              :variant="orderTypeColor(props.row.order_type)"
              class="w-100 badge-padding"
            >
              {{ props.row.order_type }}
            </b-badge>
          </div>

          <div v-else-if="props.column.field === 'sender'">
            {{ props.row.sender_location.first_name }}
            {{ props.row.sender_location.last_name }}
          </div>

          <div v-else-if="props.column.field === 'recipient'">
            {{ props.row.recipient_location.first_name }}
            {{ props.row.recipient_location.last_name }}
          </div>

          <div v-else-if="props.column.field === 'subtotal'">
            {{
              $helpers.formatTotal(
                subtotal(props.row.quantity, props.row.unit_price)
              )
            }}
          </div>

          <div v-else-if="props.column.field === 'taxcol'">
            {{ percentageFormat(props.row.tax ? props.row.tax : 0) }}
          </div>

          <div v-else-if="props.column.field === 'grand_total'">
            {{ $helpers.formatTotal(props.row.amount) }}
          </div>

          <div v-else-if="props.column.field === 'costing'">
            {{ $helpers.formatTotal(props.row.unit_price_cost) }}
          </div>

          <div v-else-if="props.column.field === 'commiss'">
            {{ $helpers.formatTotal(props.row.commission) }}
          </div>

          <div v-else-if="props.column.field === 'Outlet_profit'">
            {{ $helpers.formatTotal(props.row.profit) }}
          </div>
          <div
            v-if="props.column.field === 'convert_risk'"
            class="d-flex justify-content-center"
          >
            {{ $helpers.formatTotal(props.row.convert_risk) }}
          </div>
          <div v-else-if="props.column.field === 'status'">
            <b-button
              v-if="props.row.status === 'Active'"
              block
              variant="light-purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Assigned'"
              block
              variant="dark-purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'In Warehouse'"
              block
              variant="light-blue"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'In Transit'"
              block
              variant="yellow"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Out For Delivery'"
              block
              variant="purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Delivered'"
              block
              variant="turquoise"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Close Store'"
              block
              variant="maroon"
            >
              {{ props.row.status }}
            </b-button>
            <b-button v-else block variant="orange">
              {{ props.row.status }}
            </b-button>
          </div>

          <div v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleModalOpen(props.row)">
                <feather-icon icon="Edit2Icon" />
                Edit Costings
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </MyTable>
    </b-card>

    <!-- EDIT PRICE MODAL -->
    <b-modal
      id="edit"
      ref="edit"
      title="Edit Costing"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver ref="form">
        <form @submit.prevent="handleEditSubmit">
          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price_cost"
                name="Selling Price Cost"
                rules="required"
              >
                <b-form-group label="Selling Price Cost">
                  <b-form-input v-model="costingForm.selling_price_cost" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="double_charge_cost"
                name="Double Charge Cost"
                rules="required"
              >
                <b-form-group label="Double Charge Cost">
                  <b-form-input
                    v-model="costingForm.double_charge_cost"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="convert_risk"
                name="Convert Risk"
                rules="required"
              >
                <b-form-group label="Convert Risk">
                  <b-form-input
                    v-model="costingForm.convert_risk"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-center my-1">
            <b-button type="submit" variant="primary"> Submit </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import MyTable from '@/views/components/MyTable.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

export default {
  name: 'OutletReport',
  components: {
    vSelect,
    AppCollapse,
    AppCollapseItem,
    MyTable,
    flatPickr,
    extend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {},
      isLoading: false,
      costingForm: {},
      costingId: 0,
      total_convert_risk: 0,
      total_commission: 0,
      total_gross_profit: 0,
      selected_branch: 5678,
      selected_status_type: 'Sales Report',
      columns: [],
      report: [
        {
          label: 'Order ID',
          field: 'idcol',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Order Date',
          field: 'order_date',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Shipment',
          field: 'shipment',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: "Sender's Name",
          field: 'sender',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: "Recipient's Name",
          field: 'recipient',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Origin Postcode',
          field: 'sender_location.address_postcode',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Destination Postcode',
          field: 'recipient_location.address_postcode',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'QTY',
          field: 'quantity',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Sub Total',
          field: 'subtotal',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Tax',
          field: 'taxcol',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Payment Method',
          field: 'payment_method',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Grand Total',
          field: 'grand_total',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
      ],
      profitloss: [
        {
          label: 'Order ID',
          field: 'idcol',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Order Date',
          field: 'order_date',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Shipment',
          field: 'shipment',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Origin Postcode',
          field: 'sender_location.address_postcode',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Destination Postcode',
          field: 'recipient_location.address_postcode',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'QTY',
          field: 'quantity',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Sub Total',
          field: 'subtotal',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Tax',
          field: 'taxcol',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Grand Total',
          field: 'grand_total',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Costing',
          field: 'costing',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Outlet Profit',
          field: 'Outlet_profit',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Commission',
          field: 'commiss',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Payment Method',
          field: 'payment_method',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
      ],
      convertrisk: [
        {
          label: 'Order ID',
          field: 'idcol',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Order Date',
          field: 'order_date',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Pickup Date',
          field: 'pickup',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Delivery Date',
          field: 'delivery',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Shipment',
          field: 'shipment',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Destination Postcode',
          field: 'recipient_location.address_postcode',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'QTY',
          field: 'quantity',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
        {
          label: 'Grand Total',
          field: 'grand_total',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Convert Risk',
          field: 'convert_risk',
          thClass: 'text-nowrap',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-nowrap',
          tdClass: 'align-middle',
        },
      ],
      rows: [],
      items: [],
      date_end: new Date().toISOString().split('T')[0],
      date_start: new Date(Date.now() - 172800000).toISOString().split('T')[0],
      branchId: '',
      selectedOrder: null,
      statusTypeOptions: [
        { label: 'Sales Report', value: 'Sales Report' },
        { label: 'Profit & Loss Report', value: 'Profit & Loss Report' },
        { label: 'Convert Risk Report', value: 'Convert Risk Report' },
      ],
      form: {
        costing: '',
        commission: '',
        Outlet_profit: '',
        sales: '',
      },
      editable: false,
    };
  },
  computed: {
    orderTypeColor() {
      return (val) => {
        if (val === 'Outlet') return 'warning';
        if (val === 'DHL') return 'info';
        return 'primary';
      };
    },
    subtotal() {
      return (qty, price) => price * qty;
    },
    percentageFormat() {
      return (tax) => `${tax}%`;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.selected_branch = this.user.branch_id;
    this.getBranch();
    this.getOutletReports();
    this.statusChange();
  },
  methods: {
    async ExportToExcel(id) {
      const response = await this.$http.get(
        `export_report/${this.date_start}/${this.date_end}/Outlet/${id}`,
        { responseType: 'blob' }
      );
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute(
        'download',
        `OutletReports_${this.date_start}-${this.date_end}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    async getBranch() {
      const response = await this.$http.get('branch');
      if (!response.status === 200) {
        this.$swal({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        });
        this.isLoading = false;
        return;
      }
      this.branch = response.data.data;
      this.isLoading = false;
      this.getOutletReports();
    },
    async getOutletReports() {
      this.isLoading = true;
      const response = await this.$http.get(
        `get_orders_by_Outlet_group/${this.date_start}/${this.date_end}/${this.selected_branch}`
      );
      if (!response.status === 200) {
        this.$swal({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        });
        this.isLoading = false;
        return;
      }
      this.items = response.data.data;
      this.isLoading = false;
    },
    handleModalOpen(order) {
      this.selectedOrder = order;
      this.costingId = this.selectedOrder.id;
      this.costingForm.selling_price_cost = this.selectedOrder.unit_price_cost;
      this.costingForm.double_charge_cost =
        this.selectedOrder.double_charge_cost;
      this.costingForm.convert_risk = this.selectedOrder.convert_risk;
      this.$refs.edit.show();
    },
    async handleSubmit() {
      this.isLoading = true;
      const response = await this.$http.post(
        `report/insert_costing/${this.selectedOrder.id}`,
        this.form
      );
      if (!response.data.status) {
        this.isLoading = false;
        this.$swal({
          title: 'Error!',
          // text: "Sorry. There was something wrong in your request. Please try again.",
          text: response.data.message,
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        });
        return;
      }

      this.$swal({
        title: 'Edit Success!',
        // text: `You have successfully edited the computations of Order ID - ${this.selectedOrder.id}`,
        text: response.data.message,
        icon: 'success',
        showCancelButton: false,
        customClass: {
          confirmButton: 'btn-success',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = false;
          this.$refs.modal.hide();
          this.getOutletReports();
        }
      });
    },

    statusChange() {
      if (this.selected_status_type === 'Profit & Loss Report') {
        this.columns = this.profitloss;
      } else if (this.selected_status_type === 'Convert Risk Report') {
        this.columns = this.convertrisk;
      } else {
        this.columns = this.report;
      }
      this.getOutletReports();
    },

    handleEditSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .post(`update_order_costing/${this.costingId}`, this.costingForm)
            .then((response) => {
              if (response.data.status === false) {
                this.$refs.form.setErrors(response.data.errors);
                this.isLoading = false;
                return;
              }
              this.$refs.edit.hide();
              this.$swal({
                title: 'Success !',
                icon: 'success',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoading = false;
                  this.$refs.edit.hide();
                  this.getOutletReports();
                }
              });
            });
        }
      });
    },
    goToOrderSummary(id) {
      this.$router.push({
        name: 'order-summary',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/style.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.fixed-width-header-container {
  width: 180px;
}
</style>
